ion-content ion-header {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary--contrast);
}

.paid {
  --background: var(--ion-color-medium);
  color: var(--ion-color-medium-contrast);
  /*margin-left: 10px;
  margin-right: -10px;*/
}

.npaid {
  --background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  /*margin-left: 10px;
  margin-right: -10px;*/
}

.endButton {
  margin-left: 10px;
  margin-right: -5px;
}

.minw30 {
  min-width: 30%;
}

.maxw30 {
  max-width: 30%;
}

.disabledpassive {
  color: var(--ion-color-medium);
  margin-left: 0px;
  margin-right: 0px;
}

.disabledactive {
  color: var(--ion-color-success);
  margin-left: 0px;
  margin-right: 0px;
}

.printpassive {
  color: var(--ion-color-medium);
  margin-left: 0px;
  margin-right: 0px;
}

.printactive {
  color: var(--ion-color-warning);
  margin-left: 0px;
  margin-right: 0px;
}

.dangerpassive {
  color: var(--ion-color-medium);
  margin-left: 0px;
  margin-right: 0px;
}

.dangeractive {
  color: var(--ion-color-danger);
  margin-left: 0px;
  margin-right: 0px;
}

.w50px {
  width: 50px;
}

.mTop-4px {
  margin-top: -4px;
  margin-left: 8px !important;
  margin-right: -5px !important;
}

.resetZ {
  z-index: 0;
}

.staff {
  color: var(--ion-color-warning);
  font-size: smaller;
}

.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
}