.bgSale {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 5px !important;
}

.bgPO {
  background-color: var(--ion-color-medium);
  color: var(--ion-color-medium-contrast);
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 5px !important;
}

.bgPrintMode {
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 5px !important;
}

ion-footer {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}

.fullwidth {
  width: 100% !important;
  max-width: 100% !important;
}

.bgtool {
  --background: var(--ion-color-medium);
  --color: var(--ion-color-medium-contrast);
}

.bgtooltrans {
  --background: translucent;
}

.bg {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}

.bgGreen {
  --background: var(--ion-color-success);
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
}

.bgRed {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-danger-contrast);
}

.showoverflow {
  width: 100%;
  pointer-events: auto;
  overflow: auto !important;
}

.suggestions {
  width: 100%;
  max-height: 400px;
  position: fixed;
  overflow: auto;
  border-top: 5px solid var(--ion-color-success-tint);
  margin-bottom: 3px;
}

.suggestions ion-item {
  --background: #035e37;
  --color: white;
}

.bgsug {
  --background: var(--ion-color-light);
}

.posrelative {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  background: var(--ion-color-light);
}

.roundBorder {
  border: 1px solid var(--ion-color-light-tint);
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0px var(--ion-color-light-shade);
  padding: 10px;
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
}

.center{
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.total {
  display: flex;
  background-color: var(--ion-color-danger-tint);
  align-items: center;
  align-content: center;
  border-radius: 7px 0 0 7px;
}

.right {
  display: flex;
  background-color: var(--ion-color-danger-tint);
  font-weight: bolder;
  font-size: x-large;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  border-radius: 0 7px 7px 0;
  padding-right: 10px;
}

.middle {
  display: flex;
  align-items: center;
  align-content: center;
}

.end {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
}

.hidden {
  display: none;
}

.input {
  background-color: var(--ion-color-light);
}

.shop {
  height: 200px;
}

.leftalign {
  text-align: left;
  width: 70%;
  float: left;
}
.rightalign {
  text-align: right;
  width: 30%;
  float: right;
}

.fleft {
  text-align: left;
  float: left;
}
.fright {
  text-align: right;
  float: right;
}

.inputE {
  pointer-events: auto;
}

.textcenter {
  text-align: center;
}

.textr {
  text-align: right;
}

.marginR8 {
  margin-right: 8px !important;
}

.margin0 {
  margin: 0 !important;
}

.marginR15 {
  margin-right: 15px;
}

.paddingR15 {
  padding-right: 15px;
}

.paddingR10 {
  padding-right: 10px;
}

.inlineButton {
  margin-top: -8px !important;
  margin-left: -8px !important;
  margin-bottom: -2px !important;
  margin-right: -12px !important;
}

.inlineCheckbox {
  --border-radius: 10px;
  margin-right: 4px;
}

.saleQbutton {
  margin-left: 0;
}

.addSaleAlert .alert-wrapper {
  background: darkblue;
}

.icon{
  height: 25px;
  width: 25px;
}

.floatingNow {
  position: fixed;
  top: 115px;
  left: 10px;
  z-index: 3;
  color: black;
  --border-color: black;
}

.exportb {
  position: absolute;
  right: 20px;
}