.neugheim {
    border: 5px solid var(--ion-color-success-tint);
}

.bggheim {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    padding-right: 5px !important;
}
